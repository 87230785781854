<template>
    <nuxt-link :id="blok._uid"
        :to="url">
        <div class="relative h-min">
            <nuxt-picture :src="blok.icon?.filename"
                :alt="blok.icon?.alt"
                provider="storyblok"
                sizes="xs:40vw sm:296px md:208px lg:130px xl:173px 2xl:224px"
                class="w-full"
                :preload="{ fetchPriority: 'low' }"
                :width="size?.width"
                :height="size?.height"
                fit="in"
                :img-attrs="{
                    // eslint-disable-next-line max-len
                    class: 'w-full h-full hover:scale-110 transition duration-500 cursor-pointer object-cover',
                }" />

            <div v-if="blok.title"
                class="absolute left-1/2 top-1/2 w-full -translate-x-1/2 -translate-y-1/2
                bg-white/70 py-xs text-center text-2xl font-bold">
                {{ blok.title }}
            </div>
        </div>
    </nuxt-link>
</template>

<script setup lang="ts">
import type { BlokComponent } from '~~/types';

const properties = defineProps<{
    blok: Extract<BlokComponent, { component: 'sb-brands-grid' | 'sb-brands-grid-brand' }>
}>();

const { format: formatSbUrl } = useSbUrl();
const url = computed(() => formatSbUrl(properties.blok.link?.cached_url));

const size = computed(() => (
    properties.blok.icon?.filename
        ? useStoryblokImage(properties.blok.icon.filename)
        : undefined
));
</script>
